<template>
    <AppPaginatedGrid
        ref="grid"
        :service="service"
        permission="gestaosesmt_cadastros_riscos"
        newPath="/riscos/new"
        editPath="/riscos/"
        @onToggleMenu="onToggleMenu"
        :expander="false"
        subtitle="Perigo/Fator de Risco"
        tooltip="Registro de Perigo/Fator de Risco"
        :tipo="tipoFiltroEnum.SISTEMA"
        :modulo="moduloFiltroEnum.RISCOS"
        descricao="FILTRO PADRÃO RISCOS"
        :hasFiltro="false"
        :filtrosExtras="filtrosExtras"
        nomeTelaDoManual="riscos-list"
        origemHistoricoAcao="/riscos"
    >
        <template #anotherFilters>
            <FiltrosExtrasRiscos @onChangeFiltro="onChangeFiltro" class="lg:w-6" />
        </template>
        <template #columns>
            <Column field="id" :sortable="true" sortField="riscos.id" header="Cód." />
            <Column field="nome" :sortable="true" sortField="riscos.nome" header="Nome" />
            <Column :sortable="true" sortField="riscos.tipo_avaliacao" header="Tipo avaliação">
                <template #body="{ data }">
                    {{ getLabelTipoAvaliacao(data?.tipoAvaliacao).label }}
                </template>
            </Column>
            <Column :sortable="true" sortField="riscos.grupo_risco" header="Grupo">
                <template #body="{ data }">
                    {{ getLabelGrupoRisco(data?.grupoRisco).label }}
                </template>
            </Column>
            <Column field="codigoSoc" :sortable="true" sortField="riscos.codigo_soc" header="Cód. SOC"> </Column>
            <Column field="created_at" :sortable="true" sortField="riscos.created_at" header="Criado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.createdAt) }}
                    <small class="block text-500"> por {{ slotProps.data.userCreate?.name }} </small>
                </template>
            </Column>

            <Column field="updated_at" :sortable="true" sortField="riscos.updated_at" header="Atualizado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.updatedAt) }}
                    <small class="block text-500"> por {{ slotProps.data.userUpdate?.name }} </small>
                </template>
            </Column>
            <Column field="checklist">
                <template #body="{ data }">
                    <i
                        v-if="data?.checklist"
                        class="pi pi-check-circle mr-2"
                        style="color: var(--green-500)"
                        v-tooltip.bottom="'Utilizado no levantamento'"
                    ></i>
                </template>
            </Column>
        </template>
    </AppPaginatedGrid>
</template>

<script>
import SesmtService from '../../services/SesmtService';
import { getCurrentCompany } from '@/services/store';
import ModuloFiltroEnum from '../../enums/ModuloFiltroEnum';
import TipoFiltroEnum from '../../enums/TipoFiltroEnum';
import GrupoRiscos from '../../enums/GrupoRiscos';
import TipoAvaliacaoRisco from '../../enums/TipoAvaliacaoRisco';
import FiltrosExtrasRiscos from './components/FiltrosExtrasRiscos.vue';

export default {
    components: { FiltrosExtrasRiscos },
    data() {
        return {
            record: {},
            page: 1,
            total: 0,
            loading: false,
            totalPage: 0,
            perPage: 10,
            recordDialog: false,
            deleteRecordDialog: false,
            filtrosExtras: {},
            gruposRiscos: [
                { label: 'Físico', value: GrupoRiscos.FISICO },
                { label: 'Químico', value: GrupoRiscos.QUIMICO },
                { label: 'Biológico', value: GrupoRiscos.BIOLOGICO },
                { label: 'Ergonômico', value: GrupoRiscos.ERGONOMICO },
                { label: 'Acidente/Mecânico', value: GrupoRiscos.ACIDENTE_MECANICO },
                { label: 'Outros', value: GrupoRiscos.OUTRO }
            ],
            tiposAvaliacoes: [
                { label: 'Critério quantitativo', value: TipoAvaliacaoRisco.CRITERIO_QUANTITATIVO },
                { label: 'Critério qualitativo', value: TipoAvaliacaoRisco.CRITERIO_QUALITATIVO },
                { label: 'Faixa de conforto', value: TipoAvaliacaoRisco.FAIXA_DE_CONFORTO }
            ],
            filter: []
        };
    },
    created() {
        this.service = new SesmtService('/riscos');
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editando Perigo/Fator de Risco' : 'Adicionando Perigo/Fator de Risco';
        },
        tenant() {
            return getCurrentCompany();
        },
        tipoFiltroEnum() {
            return TipoFiltroEnum;
        },
        moduloFiltroEnum() {
            return ModuloFiltroEnum;
        }
    },
    watch: {
        tenant() {
            this.load();
        }
    },
    methods: {
        onToggleMenu(event, data) {
            this.record = data;
        },

        getLabelTipoAvaliacao(value) {
            const tipoAvaliacao = this.tiposAvaliacoes.find((p) => p.value === value);
            return tipoAvaliacao || { label: 'Não informado' };
        },
        getLabelGrupoRisco(value) {
            const grupoRisco = this.gruposRiscos.find((p) => p.value === value);
            return grupoRisco || { label: 'Não informado' };
        },
        onChangeFiltro(filtrosExtras) {
            this.filtrosExtras = filtrosExtras;
            this.$refs.grid.load();
        }
    }
};
</script>
