<template>
    <div class="mt-3 mb-3">
        <div class="flex flex-row p-fluid mt-2 md:mt-0 justify-items-between align-content-end">
            <div class="field w-4 mr-4">
                <label for="tipoAvaliacao">Tipo Avaliação</label>
                <Dropdown
                    id="tipoAvaliacao"
                    placeholder="Selecione..."
                    v-model="filters.selectedTipoAvaliacao"
                    :options="optionsTipoAvaliacao"
                    optionValue="value"
                    optionLabel="label"
                    class="step6"
                />
            </div>
            <div class="field w-4 mr-4">
                <label for="grupoRisco">Grupo Risco</label>
                <Dropdown
                    id="grupoRisco"
                    placeholder="Selecione..."
                    v-model="filters.selectedGrupoRisco"
                    :options="optionsGrupoRisco"
                    optionValue="value"
                    optionLabel="label"
                    class="step7"
                />
            </div>
            <div class="flex align-items-center justify-content-center">
                <Checkbox v-model="filters.checklist" :binary="true" class="mr-2 step8" />
                <label for="checklist">Listado no checklist de perigo</label>
            </div>
        </div>

        <div class="flex flex-row p-fluid mt-2 md:mt-0 justifyl-items-between">
            <Chips ref="chippesquisa" v-model="filters.text" placeholder="Pesquisar" class="w-full step1" />
            <Button label="Aplicar filtros" icon="pi pi-search" class="p-button p-button-success w-4 ml-2 step9" @click="aplicarFiltros($event)" />
            <Button label="Limpar filtros" icon="pi pi-times" class="p-button p-button-primary w-4 ml-2 step10" @click="removerTodosFiltros" />
        </div>
    </div>
</template>

<script>
import GrupoRiscos, { GrupoRiscosLabelEnum } from '../../../enums/GrupoRiscos';
import TipoAvaliacaoRisco, { TipoAvaliacaoRiscoLabelEnum } from '../../../enums/TipoAvaliacaoRisco';
export default {
    emits: ['onChangeFiltro'],
    data() {
        return {
            checklist: false,
            filtrosExtras: {},
            filters: {},
            optionsGrupoRisco: [
                {
                    value: GrupoRiscos.FISICO,
                    label: GrupoRiscosLabelEnum.get(GrupoRiscos.FISICO)
                },
                {
                    value: GrupoRiscos.BIOLOGICO,
                    label: GrupoRiscosLabelEnum.get(GrupoRiscos.BIOLOGICO)
                },
                {
                    value: GrupoRiscos.QUIMICO,
                    label: GrupoRiscosLabelEnum.get(GrupoRiscos.QUIMICO)
                },
                {
                    value: GrupoRiscos.ERGONOMICO,
                    label: GrupoRiscosLabelEnum.get(GrupoRiscos.ERGONOMICO)
                },
                {
                    value: GrupoRiscos.ACIDENTE_MECANICO,
                    label: GrupoRiscosLabelEnum.get(GrupoRiscos.ACIDENTE_MECANICO)
                },
                {
                    value: GrupoRiscos.OUTRO,
                    label: GrupoRiscosLabelEnum.get(GrupoRiscos.OUTRO)
                }
            ],
            optionsTipoAvaliacao: [
                {
                    value: TipoAvaliacaoRisco.CRITERIO_QUANTITATIVO,
                    label: TipoAvaliacaoRiscoLabelEnum.get(TipoAvaliacaoRisco.CRITERIO_QUANTITATIVO)
                },
                {
                    value: TipoAvaliacaoRisco.CRITERIO_QUALITATIVO,
                    label: TipoAvaliacaoRiscoLabelEnum.get(TipoAvaliacaoRisco.CRITERIO_QUALITATIVO)
                },
                {
                    value: TipoAvaliacaoRisco.FAIXA_DE_CONFORTO,
                    label: TipoAvaliacaoRiscoLabelEnum.get(TipoAvaliacaoRisco.FAIXA_DE_CONFORTO)
                }
            ]
        };
    },
    watch: {
        'filters.selectedTipoAvaliacao'() {
            this.createFiltersWhere();
        },
        'filters.selectedGrupoRisco'() {
            this.createFiltersWhere();
        },
        'filters.checklist'() {
            this.createFiltersWhere();
        },
        'filters.text'() {
            this.createFiltersWhere();
            this.$emit('onChangeFiltro', this.filtrosExtras);
        }
    },
    methods: {
        createFiltersWhere() {
            const where = [
                {
                    prop: 'riscos.nome',
                    operator: 'contains',
                    values: this.filters?.text,
                    ilikeAny: true
                }
            ];

            if (this.filters?.selectedTipoAvaliacao) {
                where.push({
                    prop: 'riscos.tipo_avaliacao',
                    operator: 'equal',
                    values: [this.filters.selectedTipoAvaliacao]
                });
            }
            if (this.filters?.selectedGrupoRisco) {
                where.push({
                    prop: 'riscos.grupo_risco',
                    operator: 'equal',
                    values: [this.filters.selectedGrupoRisco]
                });
            }
            if (this.filters?.checklist) {
                where.push({
                    prop: 'riscos.checklist',
                    operator: 'equal',
                    values: [this.filters.checklist]
                });
            }

            this.filtrosExtras = {
                where
            };
        },
        aplicarFiltros(event) {
            const inputValue = this.$refs.chippesquisa.inputValue;
            if (inputValue && inputValue.trim().length) {
                this.$refs.chippesquisa.addItem(event, inputValue, true);
            }
            this.$emit('onChangeFiltro', this.filtrosExtras);
        },
        removerTodosFiltros() {
            this.filters = {};
        }
    }
};
</script>
